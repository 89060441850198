var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-expansion-panels', [_vm.versions.length ? _vm._l(_vm.versions, function (version, index) {
    var _version$user;

    return _c('v-expansion-panel', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.getVersion(version);
        }
      }
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s((_version$user = version.user) === null || _version$user === void 0 ? void 0 : _version$user.displayName) + " made " + _vm._s(_vm.$pluralize('changes', version.count, true)) + " on " + _vm._s(_vm._f("date")(version.createdAt)) + " at " + _vm._s(_vm._f("time")(version.createdAt)) + " "), _c('v-spacer'), _vm._v(" " + _vm._s(version.version) + " ")], 1), _c('v-expansion-panel-content', {
      key: version.key
    }, _vm._l(version.diff, function (items, key) {
      return _c('v-list-item', {
        key: key
      }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(key) + ": ")]), _vm._l(items, function (item, index) {
        return [_c('v-list-item-subtitle', {
          key: index
        }, [item.key ? _c('span', [_vm._v(_vm._s(item.key) + ":")]) : _vm._e(), _vm._v(" " + _vm._s(item.type) + " " + _vm._s(item.value) + " ")])];
      })], 2)], 1);
    }), 1)], 1);
  }) : _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "text-center"
  }, [_c('v-list-item-title', [_vm._v(" No changes were made since version tracking was implemented on " + _vm._s(_vm._f("date")(_vm.createdAt)) + " ")]), _c('v-list-item-title', [_vm._v(" Space created by " + _vm._s(_vm._f("userName")(_vm.item.createdBy)) + " on " + _vm._s(_vm._f("date")(_vm.item.createdAt)) + " at " + _vm._s(_vm._f("time")(_vm.item.createdAt)) + " ")])], 1)], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }